.bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.zoneInfo {
  padding: 0.25rem;
  margin: 0 0.5rem;
}

.infoContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}

.fixCase {
  text-transform: capitalize;
}

.resource {
  padding-left: 0.25rem;
}

.controls {
  display: flex;
}

.control {
  padding: 0 0.25rem;
}

.modalBody {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;

  h2 {
    text-align: center;
  }

  .innerModal {
    width: 42rem;
    background: #333;
    padding: 1rem 4.2rem 4.2rem 4.2rem;
  }

  .innerBtn {
    margin-top: 2em;
  }
}

.cap {
  text-transform: capitalize;
}
