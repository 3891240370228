.container {
  padding-bottom: 2rem;
  padding-right: 0.8rem;
  overflow-y: scroll;
  mask-image: linear-gradient(to top, transparent, #333),
    linear-gradient(to left, transparent 17px, #333 17px);
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, #333),
  linear-gradient(to left, transparent 17px, #333 17px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;

  max-height: calc(100vh - 120px);

  &:hover {
    -webkit-mask-position: left top;
    mask-image: linear-gradient(to top, white, #aa00ff),
    linear-gradient(to left, white, #aa00ff);
    mask-size: 100% 20000px;
    mask-position: left bottom;
    -webkit-mask-image: linear-gradient(to top, white, #aa00ff),
    linear-gradient(to left, white 17px, #aa00ff 17px);
    -webkit-mask-size: 100% 20000px;
    transition: mask-position 0.3s, -webkit-mask-position 0.3s;
  }

  h3 {
    padding: 0.5rem 0;
    background-color: darken(#333, 5%);
    text-align: center;
    border-radius: 4px;
  }

  h4 {
    text-align: center;
    padding: 0.5rem 0;
  }

  a,
  a:any-link {
    color: #fdd835;
    text-decoration: none;
    font-size: 1rem;
    display: block;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
}

.col {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.zone {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.conn {
  display: block;
  margin: 0;
  padding: 0;
  height: 0.5rem;
  width: 4.2rem;

  &::after {
    content: '\00a0';
  }
}

.text {
  font-size: 1rem;
}

.two {
  background-color: #52b202;
}

.seven {
  background-color: #01b0ff;
}

.twenty {
  background-color: #ffc401;
}

.const {
  background-color: #ff3838;
}

.royal {
  background-color: #aa00ff;
}
