*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Global styles
.__________cytoscape_container {
  height: 100%;
  width: 100%;
}

.errorPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;

  button {
    border: 0;
    background: transparent;
    color: #fdd835;
  }
}
