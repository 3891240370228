.replaceContainer {
  align-items: center;
  background: url('../../common/images/mapBg.png');
  box-shadow: inset 0 0 42px rgba(0, 0, 0, 0.25);
  display: flex;
  font-size: 2rem;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.logo {
  animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
