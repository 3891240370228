.mapContainer {
  height: 100%;
  background: url('../common/images/mapBg.png');
  box-shadow: inset 0 0 42px rgba(0, 0, 0, 0.25);
  width: calc(100% - 1rem);
}

.cyto {
  height: calc(100% - 6rem);
  width: 100%;
}
