.main {
  align-items: center;
  display: flex;

  .icon {
    margin-right: 1rem;
  }
}

.details {
  display: flex;
  flex-direction: row;
}

.row {
  margin-bottom: 2rem;
}
