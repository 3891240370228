$borderColor: rgba(0, 0, 0, 0.25);

.searchSide {
  border-right: 1px solid $borderColor;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  height: 100%;
  display: grid;
  grid-template-columns: 300px auto;
}

.header {
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding: 1rem 0 1rem 1.5rem;

  .hideIcon {
    color: rgba(255, 255, 255, 0.75);
  }
}

.mainContent {
  padding: 0.5rem 1rem 0 1rem;

  &.help {
    padding-right: 0;
  }
}

.nav {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.expand {
  transform: rotate(180deg);
}
