.logo {
  display: block;
  width: 7rem;
  margin-right: 1rem;
}

.btnContainer {
  margin-top: 1.5rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  h2 {
    font-size: 1.75rem;
    opacity: 0.9;
    font-weight: 600;

    &.publicNote {
      font-size: 1.25rem;
    }
  }
}

.disclaimer {
  margin: 1rem 0.5rem;
  font-size: 0.8rem;
  font-weight: normal;
  text-align: center;
  opacity: 0.8;
}

.discordLink {
  margin-top: 1rem;
  font-size: 1rem;

  div {
    margin: 0.5rem 0;
  }
}
