.logo {
  margin-right: 1.5rem;
}

.login {
  text-align: center;
}

.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mapDisplay {
  width: 100%;
  height: 100%;
}

.mappingBar {
  display: flex;
  flex-direction: column;
}

.cytoBelow {
  margin-top: 1rem;
}

.mapStyle {
  width: 250px;
}

.flexColumn {
  display: flex;
  flex-direction: row;
}
