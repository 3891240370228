.layout {
  display: grid;
  grid-template-columns: 500px calc(100% - 400px);
  height: 100%;
  padding: 0;
  transition: 250ms;
  min-height: 100vh;

  &.hideSidebar {
    display: flex;
  }
}
